import React, { FunctionComponent } from 'react';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import Layout, { ChildrenWrapper, JustifyContentType } from '@kvika/layout';
import Header from '@kvika/header';
import { prismicConstants } from '../utils/Constants';
import { PageNotFound } from '../types/PrismicTypes';
import OnboardingPrismicClient from '../utils/Prismic/OnboardingPrismicClient';
import { PreviewData } from '../types/Types';
import LayoutImage from '../components/layout/LayoutImage';
import { getNodesFromConnection } from '../utils/Prismic/PrismicBaseUtils';

type Props = {
  errorPage: PageNotFound;
};

const Home: FunctionComponent<Props> = ({ errorPage }: Props) => {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <>
      <Head>
        <title>Kvika</title>
      </Head>
      <Layout image={<LayoutImage url={errorPage.background.url} alt={errorPage.background.alt} />}>
        <Header />
        <ChildrenWrapper justifyContent={JustifyContentType.Center}>
          <h1>{errorPage.error[0].text}</h1>
          <h3>{errorPage.errordescription[0].text}</h3>
        </ChildrenWrapper>
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ previewData }) => {
  const prismicClient = new OnboardingPrismicClient(prismicConstants.apiToken, prismicConstants.prismicRef);
  const variables = { tags: undefined, uId: undefined, lang: 'is' };

  const errorPagesResponse = await prismicClient.get404ErrorPage({
    previewData: previewData as PreviewData,
    variables,
  });

  const errorPages: PageNotFound[] = getNodesFromConnection(errorPagesResponse);
  // We should always have one error page returned
  const errorPage = errorPages && errorPages.length > 0 ? errorPages[0] : null;

  if (!errorPage) {
    // Throw this error so that the build fails if we have a undefined error page
    throw new Error('Prismic did not find an error page');
  }
  return {
    props: {
      errorPage,
    },
  };
};

export default Home;
